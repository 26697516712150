import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `FMCorreia`
  const content = data.allMarkdownRemark.nodes
  const posts = content.filter(node => node.frontmatter.category !== "project").slice(0,3)
  const projects = content.filter(node => node.frontmatter.category === "project").slice(0,2)



  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Homepage" />
    
      <section className="index-home-section sans">
        <div className="index-home-bg-section">
        <div className="content-home-section">
        <div>
        <svg id="homepage-svg" viewBox="0 0 200 170" width="400px" >
            <line x1="30" y1="40" x2="80" y2="70" stroke="white"/>
            <line x1="30" y1="40" x2="120" y2="20" stroke="white"/>
            <line x1="30" y1="40" x2="180" y2="60" stroke="white"/>
            <line x1="30" y1="40" x2="60" y2="140" stroke="white"/>
            <line x1="30" y1="40" x2="150" y2="150" stroke="white"/>
            
            <line x1="80" y1="70" x2="120" y2="20" stroke="white"/>
            <line x1="80" y1="70" x2="180" y2="60" stroke="white"/>
            <line x1="120" y1="20" x2="60" y2="140" stroke="white"/>
            <line x1="120" y1="20" x2="150" y2="150" stroke="white"/>
            
            <line x1="180" y1="60" x2="60" y2="140" stroke="white"/>
            <line x1="180" y1="60" x2="150" y2="150" stroke="white"/>

            <circle cx="30" cy="40" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <circle cx="80" cy="70" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <circle cx="120" cy="20" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <circle cx="180" cy="60" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <circle cx="60" cy="140" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <circle cx="150" cy="150" r="10" fill="white" stroke="#005b99" stroke-width="1px" class="circlehp-svg"/>
            <image class="emojieshp-svg" x="25" y="35" width="10" height="10" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNzg3IDcuNTMxYy01LjEwNyAyLjc4NS0xMi43MiA5LjE3Ny0xNS43ODcgMTUuNDY5aDIuOTM5Yy44MTktMi4wMjEgMi41MjItNC41MzYgMy44NTEtNS45MDIgOC4zODYgMy43NDcgMTcuMjEtMi43NzUgMTcuMjEtMTEuMzQzIDAtMS41MzUtLjMwMi0zLjEzNi0uOTItNC43NTUtMi4zNDcgMy4xMTktNS42NDcgMS4wNTItMTAuODUxIDEuNjI1LTcuNjU3Ljg0NC0xMS4xNjIgNi43OTctOC43NjQgMTEuNTQgMy41MDYtMy40MTUgOS41MjMtNi4zOCAxMi4zMjItNi42MzR6Ii8+PC9zdmc+"></image>
            
            <image class="emojieshp-svg" x="75" y="65" width="10" height="10" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuMjUgNmMuNDE0IDAgLjc1LjMzNi43NS43NXY5LjVjMCAuNDE0LS4zMzYuNzUtLjc1Ljc1aC05LjVjLS40MTQgMC0uNzUtLjMzNi0uNzUtLjc1di05LjVjMC0uNDE0LjMzNi0uNzUuNzUtLjc1aDkuNXptMi43NSAwYzAtMS4xMDQtLjg5Ni0yLTItMmgtMTFjLTEuMTA0IDAtMiAuODk2LTIgMnYxMWMwIDEuMTA0Ljg5NiAyIDIgMmgxMWMxLjEwNCAwIDItLjg5NiAyLTJ2LTExem0tMTEgMTR2M2gtMXYtM2gxem00IDB2M2gtMXYtM2gxem0yIDB2M2gtMXYtM2gxem0tNCAwdjNoLTF2LTNoMXptNiAwdjNoLTF2LTNoMXptLTgtMjB2M2gtMXYtM2gxem00IDB2M2gtMXYtM2gxem0yIDB2M2gtMXYtM2gxem0tNCAwdjNoLTF2LTNoMXptNiAwdjNoLTF2LTNoMXptNCAxNWgzdjFoLTN2LTF6bTAtNGgzdjFoLTN2LTF6bTAtMmgzdjFoLTN2LTF6bTAgNGgzdjFoLTN2LTF6bTAtNmgzdjFoLTN2LTF6bS0yMCA4aDN2MWgtM3YtMXptMC00aDN2MWgtM3YtMXptMC0yaDN2MWgtM3YtMXptMCA0aDN2MWgtM3YtMXptMC02aDN2MWgtM3YtMXoiLz48L3N2Zz4="></image>
            <image class="emojieshp-svg" x="115" y="15" width="10" height="10" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNSA1aC0zdi0xaDN2MXptOCA1Yy0xLjY1NCAwLTMgMS4zNDYtMyAzczEuMzQ2IDMgMyAzIDMtMS4zNDYgMy0zLTEuMzQ2LTMtMy0zem0xMS00djE1aC0yNHYtMTVoNS45M2MuNjY5IDAgMS4yOTMtLjMzNCAxLjY2NC0uODkxbDEuNDA2LTIuMTA5aDhsMS40MDYgMi4xMDljLjM3MS41NTcuOTk1Ljg5MSAxLjY2NC44OTFoMy45M3ptLTE5IDRjMC0uNTUyLS40NDctMS0xLTEtLjU1MyAwLTEgLjQ0OC0xIDFzLjQ0NyAxIDEgMWMuNTUzIDAgMS0uNDQ4IDEtMXptMTMgM2MwLTIuNzYxLTIuMjM5LTUtNS01cy01IDIuMjM5LTUgNSAyLjIzOSA1IDUgNSA1LTIuMjM5IDUtNXoiLz48L3N2Zz4="></image>
            <image class="emojieshp-svg" x="175.5" y="55.5" width="9" height="9" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMiAyNGgtMTdjLTEuNjU3IDAtMy0xLjM0My0zLTN2LTE4YzAtMS42NTcgMS4zNDMtMyAzLTNoMTd2MjR6bS0yLTRoLTE0LjUwNWMtMS4zNzUgMC0xLjM3NSAyIDAgMmgxNC41MDV2LTJ6bS0zLTE1aC0xMHYzaDEwdi0zeiIvPjwvc3ZnPg=="></image>
            <image class="emojieshp-svg" x="55" y="135" width="10" height="10" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNy41NjUgOS4yMDljLjQ1NC0uMTM2LjkzNy0uMjA5IDEuNDM1LS4yMDkgMi43NTkgMCA1IDIuMjQgNSA1cy0yLjI0MSA1LTUgNWMtMi43NiAwLTUtMi4yNC01LTUgMC0xLjkwNiAxLjA2OC0zLjU2NCAyLjYzOS00LjQwOGwtLjQyOS0xLjAzOS00LjQ5NCA1Ljk0N2gtMS43NDFjLS4yNTEgMi41MjUtMi4zODUgNC41LTQuOTc1IDQuNS0yLjc2IDAtNS0yLjI0LTUtNXMyLjI0LTUgNS01Yy42MzUgMCAxLjI0NC4xMTkgMS44MDMuMzM2bDEuMTgxLTIuMzMxLS40NjItMS4wMDVoLTEuMDIyYy0uMjc3IDAtLjUtLjIyNC0uNS0uNSAwLS4yMzkuMTg5LS41LjUtLjVoMi40OTFjLjIzOSAwIC41LjE4OS41LjVzLS4yNi41LS41LjVoLS4zNjhsLjQ3IDFoNi40ODRsLS40MjEtMWgtMS42NTZjLS4yNzcgMC0uNS0uMjI0LS41LS41IDAtLjMxMS4yNTktLjUuNS0uNWgyLjMzbDEuNzM1IDQuMjA5em0tMTEuMjE3IDEuMDI0Yy0uNDIxLS4xNTEtLjg3NS0uMjMzLTEuMzQ4LS4yMzMtMi4yMDggMC00IDEuNzkyLTQgNHMxLjc5MiA0IDQgNGMyLjAzOCAwIDMuNzIyLTEuNTI4IDMuOTY5LTMuNWgtMy4xMDNjLS4xNzQuMjk5LS40OTcuNS0uODY2LjUtLjU1MiAwLTEtLjQ0OC0xLTEgMC0uNTMzLjQxOS0uOTcuOTQ1LS45OThsMS40MDMtMi43Njl6bTEwLjY3NS4yODljLTEuMjA4LjY4OS0yLjAyMyAxLjk4OS0yLjAyMyAzLjQ3OCAwIDIuMjA4IDEuNzkyIDQgNCA0czQtMS43OTIgNC00LTEuNzkyLTQtNC00Yy0uMzY0IDAtLjcxNi4wNDktMS4wNTEuMTRsMS4xODIgMi44NjljLjQ5MS4wNjQuODY5LjQ4NC44NjkuOTkxIDAgLjU1Mi0uNDQ5IDEtMSAxLS41NTIgMC0xLS40NDgtMS0xIDAtLjIyOS4wNzctLjQ0LjIwNy0uNjA5bC0xLjE4NC0yLjg2OXptLTkuNzgzLjE2NWwtMS40MDMgMi43NjYuMDI5LjA0N2gzLjEwM2MtLjE0Ny0xLjE2OS0uNzk4LTIuMTgzLTEuNzI5LTIuODEzbS40NTQtLjg5OGMxLjI1NC44MDQgMi4xMjYgMi4xNTIgMi4yODEgMy43MTFoLjk5N2wtMi40NTQtNS4zMzYtLjgyNCAxLjYyNXptNy42ODMtMS43ODloLTUuODM5bDIuMjEyIDQuNzk3IDMuNjI3LTQuNzk3eiIvPjwvc3ZnPg=="></image>
            <image class="emojieshp-svg" x="145" y="145" width="10" height="10" alignment-baseline="middle" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMgMjIuNTg5djEuNDExaC0ydi0xLjQyNWMwLTEuOTU4LS45NDMtMy4wMTUtMi00LjU3NWg2Yy0xLjA2MiAxLjU1My0yIDIuNjEyLTIgNC41ODl6bTMtOS41ODljLS41NTIgMC0xLS40NDgtMS0xcy40NDgtMSAxLTFoMnYtMmgtMmMtLjU1MiAwLTEtLjQ0OC0xLTFzLjQ0OC0xIDEtMWgydi0yaC0yYy0uNTUyIDAtMS0uNDQ4LTEtMXMuNDQ4LTEgMS0xaDEuODU4Yy0uNDQ2LTEuNzIyLTEuOTk3LTMtMy44NTgtM2gtNGMtMS44NjEgMC0zLjQxMiAxLjI3OC0zLjg1OCAzaDEuODU4Yy41NTIgMCAxIC40NDggMSAxcy0uNDQ4IDEtMSAxaC0ydjJoMmMuNTUyIDAgMSAuNDQ4IDEgMXMtLjQ0OCAxLTEgMWgtMnYyaDJjLjU1MiAwIDEgLjQ0OCAxIDFzLS40NDggMS0xIDFoLTEuODU4Yy40NDYgMS43MjIgMS45OTcgMyAzLjg1OCAzaDRjMS44NjEgMCAzLjQxMi0xLjI3OCAzLjg1OC0zaC0xLjg1OHoiLz48L3N2Zz4="></image>
          </svg>
          </div>

          <div className="index-home-section-text">            
          <h2>Filipe Correia</h2>
            <p>Officially, studying informatics and computing engineering at FEUP.</p>
            <p>In his free time, a writer, speaker and a photographer.</p>
        </div>
        </div>
        </div>
      </section>

      <section className="index-about-section sans">
      <div className="index-about-section-text">
        <h2>About</h2>
        <p>Filipe Correia is a student interested in how things are made and how the world works in general.</p>
        <p>On this website, he shares some curiosities about technology, psychology, philosophy, art and other subjects that go over his mind.</p>
      </div>
      </section>
    <section className="index-blog-section">
    <div className="index-blog-section-text">
      <h2>Blog</h2>

      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h3>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h3>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      <Link to="/blog" className="button2">Blog</Link>
      <a href="https://dev.fmcorreia.com" className="button2">Dev Articles</a>
      </div>
      </section>
      <section className="index-projects-section">
          <div className="index-projects-section-text">
            <h2>Projects</h2>
            <p>Find out some of Filipe's projects, including all the work done behind the scenes and some thought-provoking details!</p>
          
            <div className="flex-column index-projects-column">
        {projects.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const slug = "projects" + post.fields.slug
          return (
              <div>
                  <h3>
                    <Link to={slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                <Link className="button3" to={slug}>Know more</Link>

              </div>
          )
        })}
      </div>
            <Link className="project-button button1" to="/projects">Other Projects</Link>         
          </div>
      </section>

      


      
      <section id="get-in-touch" className="index-get-touch-section">
        <div className="index-get-touch-section-text sans">
          <h2>Get In Touch</h2>
          <p>Send Filipe an email at hello@fmcorreia.com, send a message <span className="open-get-touch">here</span> or schedule a call with him <a id="book-schedule" href="https://go.fmcorreia.com/booking">here</a>.</p>

        </div>
      </section>  
      <section className="index-footer-fmcorreia">
        <footer>
          <div className="index-footer-fmcorreia-text">
            <h2>FMCorreia</h2>
          </div>
        </footer>
      </section>

    </Layout>
  )
}

/*
<div className="index-get-touch-form-div">
          <form className="index-get-touch-form" method="post" netlify-honeypot="not-for-you-field" data-netlify="true" name="contact">
            <label for="not-a-field" className="nothing"> 
              If you are a human being, do not fill this out:
              <input className="index-get-touch-form-input" id="not-a-field" name="not-for-you-field" />
            </label>
              <input placeholder="NAME" className="index-get-touch-form-input" id="name" required name="name" type="text"/>
              <input placeholder="EMAIL" className="index-get-touch-form-input" required id="mail" name="email" type="email"/>
              <input placeholder="SUBJECT" className="index-get-touch-form-input" required id="subject" name="subject" type="text"/>              
              <textarea onInput={function a(){
                let thisarea = document.getElementById("message")
                thisarea.style.height = "";
                thisarea.style.height = thisarea.scrollHeight + 6 + "px"}} placeholder="MESSAGE" className="index-get-touch-form-input" required id="message" name="message" type></textarea>
            <button className="button1" type="submit">Send</button>
          </form>
          </div> 


          <section className="index-newsletter-section">
        
        <div className="newsletter-div">
        <h2>Newsletter</h2>

        <p>If you don't want to miss anything, subscribe to my newsletter!<br></br>
        Your data is safe! Everything complies with <Link href="privacy">FMCorreia's privacy policy</Link>.</p>

        <form method="post" action="https://sendfox.com/form/1gln00/1xzjyy" class="sendfox-form" id="1xzjyy" data-async="true" data-recaptcha="true">
          <input type="text" className="form-input" id="sendfox_form_name" placeholder="First Name" name="first_name" required />
          <input className="form-input" type="email" id="sendfox_form_email" placeholder="Email" name="email" required />
          <p><label><input type="checkbox"  name="gdpr" value="1" required /> I agree to receive email updates.</label></p>
          <div className="btz-control" aria-hidden="true"><input type="text" name="a_password" tabindex="-1" value="" autocomplete="off" /></div>
          <p><button className="button1" type="submit">Submit</button></p>
        </form>
        <script src="https://sendfox.com/js/form.js"></script>
			
        </div>

      </section>

*/




export default BlogIndex

export const pageQuery = graphql`
{
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {language: {ne: "pt"}}}
  ) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        category
      }
    }
  }
}

`
